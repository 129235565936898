// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("popper.js");

import $ from 'jquery';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import JSONViewer from './json-viewer'

function exportToCSV() {

}

function showCopiedMessage(data) {
	const message = document.createElement('div');
	message.textContent = 'Password copied';
	message.classList.add('copied-message');
	document.body.appendChild(message);

	// Remove the message after a short delay
	setTimeout(() => {
		document.body.removeChild(message);
	}, 2000); // Adjust the delay as needed
}


function copyToClipboard() {
	$('.copy-clipboard').on('click', function (e) {
		let str = document.querySelector('.password-result-input .password-result').innerHTML.trim();
		str = str.replace("&amp;", '&').replace("&gt;", '>').replace("&lt;", '<').replace("&quot;", '"');
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		console.log(str);

		showCopiedMessage(str);
	});
}

function getAnotherPassword() {
	$('.generate-new.password').on('click', function (e) {
		$.ajax({
			url: '/api/get_another_password',
			type: 'get',
			dataType: 'json',
		}).done(function (data, textStatus, jQxhr) {
			if (textStatus === 'success') {
				const passwordResult = data.result.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;")
				$('.password-result-input .password-result').html(passwordResult)
				$('.password-reminder span').html(data.hint);
			}
		})
	});
}

function setupStickyMenu() {
	$('.menu-icon').on('click', function (e) {
		$('.overlay').show();
		$('.dropdown-menu').show();
	});

	$('.close-wrapper img').on('click', function (e) {
		$('.dropdown-menu').hide();
		$('.overlay').hide();
	});
}

function contactUs() {
	var form = $('#form-wrapper');

	$('.contact-us-btn').on('click', function (e) {
		$.ajax({
			url: '/contact_us',
			type: 'POST',
			dataType: 'json',
			data: form.serialize()
		}).done(function (data, textStatus, jQxhr) {
			const {
				error
			} = data
			if (error) {
				$('#form-wrapper .error').text(error);
			} else {
				$('#form-wrapper .error').text('');
				$('#form-wrapper .name-input').val('');
				$('#form-wrapper .email-input').val('');
				$('#form-wrapper .message-input').val('');
			}
		})
	})
}

function copyMultiToClipboard() {
	function copy(e) {
		let str = e.currentTarget.getAttribute('data-src');
		str = str.replace("&amp;", '&').replace("&gt;", '>').replace("&lt;", '<').replace("&quot;", '"');
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		console.log(str);
	}

	$('.copy-multi-clipboard').on('click', function (e) {
		copy(e)
	});

	$('.copy-all-btn').on('click', function (e) {
		let result = '';
		let passwordElmt = $('.result-string');
		for (var i = 0; i < passwordElmt.length; i++) {
			const item = passwordElmt[i].innerHTML.trim()
			const password = item.replace("&amp;", '&').replace("&gt;", '>').replace("&lt;", '<').replace("&quot;", '"');
			if (!result.split('\n').some(e => e === password)) {
				result += password + '\n'
			}
		}
		const el = document.createElement('textarea');
		el.value = result;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	});

	$('.generate-multi-btn').on('click', function (e) {
		$.ajax({
			url: '/api/get_bulk_password',
			type: 'get',
			dataType: 'json',
		}).done(function (data, textStatus, jQxhr) {
			if (textStatus === 'success') {
				var results = '';
				data.map(password => {
					results += password;
				});
				$('.table .table-body').html(results);

				$('.copy-multi-clipboard').on('click', function (e) {
					copy(e)
				});
			}
		})
	});
}

function scrollTop() {
	$(document).ready(function () {

		var btn = $('.scroll-top');

		$(window).scroll(function () {
			if ($(window).scrollTop() > 300) {
				btn.addClass('show');
			} else {
				btn.removeClass('show');
			}
		});

		btn.on('click', function (e) {
			e.preventDefault();
			$('html, body').animate({
				scrollTop: 0
			}, '300');
		});

	});
}


document.addEventListener("turbolinks:load", function () {
	copyToClipboard();
	getAnotherPassword();

	copyMultiToClipboard();
	scrollTop();
	setupStickyMenu();
	contactUs();
})
